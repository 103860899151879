import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import{
  Card,
  Group,
  Image,
  Badge,
  Button,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
} from '@mantine/core';

import {SimplePage}      from "../components/SimplePage/SimplePage"
import {updateTimeAsync} from "../store/modules/app/action.js"


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Example() {

  return (
    <div>
        <h1>Renew subscription automatically</h1>
        <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo totam non cumque deserunt officiis ex
              maiores nostrum.
        </p>
    </div>
  )
}


export function Demo() {
  const {current_time} = useSelector((state)=>state.app)
  const theme = useMantineTheme();
  const dispatch = useDispatch();

  const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];

  useEffect(()=>{
    const timestamp = new Date().getTime()
    dispatch(updateTimeAsync(timestamp));
    return ()=>{
      console.log("after effect")
    }
  },[current_time])

  return (
    <SimplePage data={{
      title:"Demo"
    }}>

    <Example/>

    </SimplePage>
  );
}

