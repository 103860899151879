import React,{useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import axios          from 'axios';
import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from '@mantine/core';
import { Alert } from '@mantine/core';
import { useLocation,useParams } from "react-router-dom";

export function Join() {

  const location = useLocation();
  const {auth}     = useParams();
  const navigate = useNavigate();
  const [email,setEmail] = useState(false);
  const [code,setCode]   = useState(false);
  const [count,setCount] = useState(0);
  const [message,setMessage] = useState(false);

  useEffect(()=>{
    const auth = "3b7e92d5-7ca7-42d0-83e5-3d6a449fb94f";
    axios.get('/common/team/' + auth,).then(({data}) => {
      console.log("==>",data)
    });
    /*
    axios.post('/user/join', {auth}).then(({data}) => {
      console.log(data)
    });
    */
  },[]);

  useEffect(()=>{
    setMessage(false)
    return ()=>{
    }
  },[email,code]);

  useEffect(()=>{
    if(count == 30){
      setTimeout(()=>{
        setCount(0);
      },20*1000)
    }
    return ()=>{
    }
  },[count]);

  const sendCode = () => {
        if(!email){
          return alert("please enter your email");
        }

        axios.post('/authorization', {
          address:email.toLowerCase()
        }).then(({data}) => {
          if (data.code == 0) {
            setCount(30);
          }
        });
  }

  const doAuth = () => {

        if(!code){
          return alert("please enter your email code");
          return;
        }

        if(!email){
          return alert("please enter your email");
        }

        axios.post('/login', {
            method:"email",
            email:email.toLowerCase(),
            mailcode:code,
            refer:localStorage.getItem("refer") || null
        }).then(({data}) => {
          if(data.code == 0){
            return navigate("/chat");
          }else{
              setMessage(data.message)
          }
        }).catch((err) => {
            console.error(err);
        });

  }


  return (
    <Container size={420} my={80}>
      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        GPTDOS Team
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Team Up Time
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <Text component="label" htmlFor="your-password" size="sm" weight={500}>
          电子邮箱
        </Text>
        <TextInput mt={5} mb={5} onChange={(e)=>{setEmail(e.target.value)}} placeholder="hello@gmail.com" size="sm" />

     <Group position="apart" mt={10} mb={5}>
        <Text component="label" htmlFor="your-password" size="sm" weight={500}>
          验证码
        </Text>

        {count > 0?<Anchor
          disabled
          sx={(theme) => ({
            paddingTop: 2,
            color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
            fontWeight: 500,
            fontSize: theme.fontSizes.xs,
          })}
          >已经发送到邮箱</Anchor>:
        <Anchor
          href="#"
          onClick={(event) => sendCode()}
          sx={(theme) => ({
            paddingTop: 2,
            color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
            fontWeight: 500,
            fontSize: theme.fontSizes.xs,
          })}
        >
          发送验证码
        </Anchor>
        }

      </Group>
        <TextInput name="code" placeholder="000000" onChange={(e) => setCode(e.target.value)}  />

        {message?
          <Alert title="登陆失败" mt={20} radius="md" variant="outline">
            {message}
          </Alert>
          :null}

        <Button fullWidth mt="xl" onClick={()=>{doAuth()}}>
          加入 Team
        </Button>

      </Paper>
    </Container>
  );
}

