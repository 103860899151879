import styled from '@emotion/styled';
import { useHover } from '@mantine/hooks';
import { useState, useCallback, useEffect, Fragment } from "react";
import {useNavigate,useLocation} from 'react-router-dom';
import { useViewportSize } from '@mantine/hooks';

import {
  Navbar,
  Text,
  MediaQuery,
  useMantineTheme,
  useMantineColorScheme,
  Button,
  ActionIcon,
  ThemeIcon,
  UnstyledButton,
  Group,
  Box
} from '@mantine/core';

import { Code, Ticket, Messages, Database } from 'tabler-icons-react';

import {User} from "./user.js";
import {Logo} from '../Logo';
import useStyles from './layout.styles';

const Container = styled.div`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    gap: 0.5rem;
    padding: 0rem 1rem;
    background: rgba(0, 0, 0, 0.2);

    h1 {
        @media (max-width: 40em) {
            width: 100%;
            order: -1;
        }

        font-family: "Work Sans", sans-serif;
        font-size: 1rem;
        line-height: 1.3;

        animation: fadein 0.5s;
        animation-fill-mode: forwards;

        strong {
            font-weight: bold;
            white-space: nowrap;
        }

        span {
            display: block;
            font-size: 70%;
            white-space: nowrap;
        }

        @keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }
    }

    .spacer {
        @media (min-width: 40em) {
            flex-grow: 1;
        }
    }
    i {
        font-size: 90%;
    }
    i + span {
        @media (max-width: 40em) {
            position: absolute;
            left: -9999px;
            top: -9999px;
        }
    }
`;

function Theme() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <ActionIcon variant="default" onClick={() => toggleColorScheme()} size={30}>
    {colorScheme === 'dark' ? <Sun size={16} /> : <MoonStars size={16} />}
    </ActionIcon>
  );
}

function HeaderButton(props) {
    return (
        <Button size='xs'
                variant={props.variant || 'subtle'}
                onClick={props.onClick}>
            {props.icon}
            {props.children && <span>
                {props.children}
            </span>}
        </Button>
    )
}


export function Header({ title,opened,data }) {
    const navigate = useNavigate();
    const path = useLocation()
    const { hovered, ref } = useHover();
    const [loading, setLoading] = useState(false);
    const { height, width } = useViewportSize();

    const onNewChat = useCallback(async () => {
        setLoading(true);
        navigate(`/chat`);
        setLoading(false);
    }, [navigate]);

    const onProfile = useCallback(async () => {
        setLoading(true);
        navigate(`/console/user`);
        setLoading(false);
    }, [navigate]);

    const onRefer = useCallback(async () => {
        setLoading(true);
        navigate(`/console/refer`);
        setLoading(false);
    }, [navigate]);

    const onPoints = useCallback(async () => {
        setLoading(true);
        navigate(`/console/points`);
        setLoading(false);
    }, [navigate]);

    const onCode = useCallback(async () => {
        setLoading(true);
        navigate(`/console/code`);
        setLoading(false);
    }, [navigate]);

    const onTeam = useCallback(async () => {
        setLoading(true);
        navigate(`/console/team`);
        setLoading(false);
    }, [navigate]);

    const onStatus = useCallback(async () => {
        setLoading(true);
        window.location = `https://status.openai.com`;
        setLoading(false);
    }, [navigate]);

    const onClients = useCallback(async () => {
        setLoading(true);
        navigate(`/console/clients`);
        setLoading(false);
    }, [navigate]);

    const onPrompts = useCallback(async () => {
        setLoading(true);
        navigate(`/console/prompts`);
        setLoading(false);
    }, [navigate]);

    const onAPI = useCallback(async () => {
        setLoading(true);
        navigate(`/console/api`);
        setLoading(false);
    }, [navigate]);

    return <Container>

        <h1 style={{cursor:"pointer"}} onClick={(e)=>{
          window.location.reload()
        }} ref={ref}>
          <strong>GPTDOS</strong><br />
          <span>OpenAI Terminal</span>
        </h1>

        <div className="spacer" />

        <HeaderButton onClick={onNewChat}  variant={path.pathname == "/chat" ? "light":"subtle" }>
            Chat
        </HeaderButton>

        <HeaderButton  onClick={onCode}  variant={path.pathname == "/console/code" ? "light":"subtle" }>
            Enter Code
        </HeaderButton>
        {/**
          <HeaderButton onClick={onRefer}  variant={path.pathname == "/console/refer" ? "light":"subtle" }>
            Refer friends
          </HeaderButton>
          **/}
        {width>800?<Fragment>
          <HeaderButton  onClick={onClients}  variant={path.pathname == "/console/clients" ? "light":"subtle" }>
            Clients/Extenstion
          </HeaderButton>

          <HeaderButton  onClick={onAPI}  variant={path.pathname == "/console/api" ? "light":"subtle" }>
            API
          </HeaderButton>

          <HeaderButton  onClick={onPoints}  variant={path.pathname == "/console/points" ? "light":"subtle" }>
            Points Shop({data.user.points_available})
          </HeaderButton>

          </Fragment>:null}

        <HeaderButton  onClick={onProfile} variant={path.pathname == "/console/user" ? "light":"subtle" }>
            Profile
        </HeaderButton>

    </Container>;
}

