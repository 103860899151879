import { produce } from "immer";
import { AnyAction } from "redux";

const defaultState = {
  start_at: new Date().getTime(),
  current_time: new Date().getTime(),
  tabs:[{id:0,title:"New Chat"}],
  activeTab:0
};

export default function (state = defaultState, action: AnyAction) {
  return produce(state, (draft) => {
    switch (action.type) {

      case "update_time":{
        const {timestamp} = action.payload;
        draft.current_time = timestamp;
      }

      case "set_active_tab":{
        const {id} = action.payload;
        draft.activeTab = id;
        break;
      }

      case "create_tab":{
        const {id,title} = action.payload;
        draft.tabs = draft.tabs.concat([{id,title}]);
        break;
      }

      case "close_tab":{
        const {id} = action.payload;
        draft.tabs = draft.tabs.filter((tab)=>tab.id != id)
        if(draft.activeTab == id && draft.tabs.length > 0){
          draft.activeTab = draft.tabs[draft.tabs.length - 1].id;
        }
        break;
      }

      case "update_tab_title":{
        const {id,title} = action.payload;
        draft.tabs.find((item)=>{return item.id == id}).title = title
        break;
      }

      default: {
        break;
      }

    }
  });
}
