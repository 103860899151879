import ReactMarkdown from 'react-markdown';
import styled from '@emotion/styled';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import detectLang from 'lang-detector';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Button, CopyButton } from '@mantine/core';


const Code = styled.div`
    padding: 0;
    border-radius: 0.25rem;
    overflow: hidden;
    &>div {
        margin: 0 !important;
    }
    .fa {
        font-style: normal !important;
    }
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #171719;
    height: 2.5rem;
    padding: 0.1rem 0.1rem 0 0.5rem;
    .mantine-Button-label {
        display: flex;
        align-items: center;
        * {
            font-size: 90%;
        }
    }
`;

export interface MarkdownProps {
    content: string;
    className?: string;
}

export function Markdown(props: MarkdownProps) {
    const classes = ['prose', 'dark:prose-invert'];

    if (props.className) {
        classes.push(props.className);
    }

    return (
        <div className={classes.join(' ')}>
            <ReactMarkdown remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeKatex]}
                components={{
                   ul({ start, children }) {
                        return <ul start={start ?? 1} style={{ counterReset: `list-item ${(start || 1) - 1}` }}>
                            {children}
                        </ul>;
                    },
                   ol({ start, children }) {
                        return <ol start={start ?? 1} style={{ counterReset: `list-item ${(start || 1) - 1}` }}>
                            {children}
                        </ol>;
                    },
                    code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '')
                        const lang = detectLang(String(children)).toLowerCase()
                        return !inline ? (
                            <Code>
                              <Header>
                                <CopyButton value={String(children)}>
                                    {({ copy, copied }) => (
                                        <Button variant="subtle" size="sm" compact onClick={copy}>
                                            <i className="fa fa-clipboard" />
                                            <span>{copied ? 'Copied' : 'Copy'}</span>
                                        </Button>
                                    )}
                                </CopyButton>
                              </Header>
                                <SyntaxHighlighter
                                    children={String(children).replace(/\n$/, '')}
                                    style={a11yDark}
                                    className="block"
                                    language={lang||"text"}
                                    PreTag="div"
                                    {...props}
                                />
                            </Code>
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        )
                    }
                }}>{props.content}</ReactMarkdown>
        </div>
    );
}
