import React from 'react';
import { Title, Text } from '@mantine/core';
import useStyles from './simple.styles';

export function SimplePage(props) {

  const { data } = props;
  const { classes, cx } = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={cx(classes.header)}>
        {data.title ? <Title className={classes.title}>{data.title}</Title> : null}
      </div>
      <div className={cx(classes.inner)}>
        {props.children}
      </div>
    </div>
  );
}
