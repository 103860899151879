import { createStyles } from '@mantine/core';

const BREAKPOINT = 300;

export default createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    zIndex: 4,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[3],
    marginLeft:"0px",
    paddingLeft: 20,
    paddingRight: 20,
    minHeight:"100vh",

    [`@media (max-width: ${BREAKPOINT}px)`]: {
      paddingLeft: theme.spacing.xl,
      paddingRight: theme.spacing.xl,
    },
  },

  header: {
    paddingTop: 10,
    maxWidth: 1082,
    paddingBottom: theme.spacing.xl * 1,
    [`@media (max-width: ${BREAKPOINT}px)`]: {
      maxWidth: '100%',
      paddingRight: 0,
      paddingBottom: theme.spacing.xl,
    },
  },


  withTabs: {
    paddingBottom: theme.spacing.xl * 1.5,
  },

  title: {
    fontSize: 32,
    fontWeight: 900,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,

    [`@media (max-width: ${BREAKPOINT}px)`]: {
      fontSize: 32,
      lineHeight: 1,
    },
  },

  description: {
    maxWidth: 450,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    marginBottom: theme.spacing.xl,

    [`@media (max-width: ${BREAKPOINT}px)`]: {
      fontSize: theme.fontSizes.md,
    },
  },
}));
