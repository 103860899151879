import styled from '@emotion/styled';
import { useDispatch, useSelector } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { Avatar } from '@mantine/core';
import { Button, Textarea } from '@mantine/core';
import { Send, AlertCircle, Messages, Database } from 'tabler-icons-react';

import "./main.css";
import Loading from "./components/Loading";
import Error from "./components/Error";
import BotResponse from "./components/BotResponse";
import PromptBox from "./components/PromptBox";
import IntroSection from "./components/IntroSection";

import {fetchUserAsync} from "../store/modules/user/action.js"
import {updateTabTitle,closeTab} from "../store/modules/app/action.js"

const ChatBox = styled.div`
    max-height: 100%;
    overflow-y: hidden;
`;



const GPT= <path d="M37.532 16.87a9.963 9.963 0 0 0-.856-8.184 10.078 10.078 0 0 0-10.855-4.835A9.964 9.964 0 0 0 18.306.5a10.079 10.079 0 0 0-9.614 6.977 9.967 9.967 0 0 0-6.664 4.834 10.08 10.08 0 0 0 1.24 11.817 9.965 9.965 0 0 0 .856 8.185 10.079 10.079 0 0 0 10.855 4.835 9.965 9.965 0 0 0 7.516 3.35 10.078 10.078 0 0 0 9.617-6.981 9.967 9.967 0 0 0 6.663-4.834 10.079 10.079 0 0 0-1.243-11.813ZM22.498 37.886a7.474 7.474 0 0 1-4.799-1.735c.061-.033.168-.091.237-.134l7.964-4.6a1.294 1.294 0 0 0 .655-1.134V19.054l3.366 1.944a.12.12 0 0 1 .066.092v9.299a7.505 7.505 0 0 1-7.49 7.496ZM6.392 31.006a7.471 7.471 0 0 1-.894-5.023c.06.036.162.099.237.141l7.964 4.6a1.297 1.297 0 0 0 1.308 0l9.724-5.614v3.888a.12.12 0 0 1-.048.103l-8.051 4.649a7.504 7.504 0 0 1-10.24-2.744ZM4.297 13.62A7.469 7.469 0 0 1 8.2 10.333c0 .068-.004.19-.004.274v9.201a1.294 1.294 0 0 0 .654 1.132l9.723 5.614-3.366 1.944a.12.12 0 0 1-.114.01L7.04 23.856a7.504 7.504 0 0 1-2.743-10.237Zm27.658 6.437-9.724-5.615 3.367-1.943a.121.121 0 0 1 .113-.01l8.052 4.648a7.498 7.498 0 0 1-1.158 13.528v-9.476a1.293 1.293 0 0 0-.65-1.132Zm3.35-5.043c-.059-.037-.162-.099-.236-.141l-7.965-4.6a1.298 1.298 0 0 0-1.308 0l-9.723 5.614v-3.888a.12.12 0 0 1 .048-.103l8.05-4.645a7.497 7.497 0 0 1 11.135 7.763Zm-21.063 6.929-3.367-1.944a.12.12 0 0 1-.065-.092v-9.299a7.497 7.497 0 0 1 12.293-5.756 6.94 6.94 0 0 0-.236.134l-7.965 4.6a1.294 1.294 0 0 0-.654 1.132l-.006 11.225Zm1.829-3.943 4.33-2.501 4.332 2.5v5l-4.331 2.5-4.331-2.5V18Z"
fill="currentColor" />

function HeaderButton(props: ButtonProps & { icon?: string, onClick?: any, children?: any }) {
    return (
        <Button size='xs'
                ml={props.ml?props.ml:0}
                variant={props.variant || 'subtle'}
                onClick={props.onClick}>
            {props.children && <span>
                {props.children}
            </span>}
        </Button>
    )
}


export default function App({id}) {
  const [chatLog, setChatLog] = useState([]);
  const [err, setErr] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state)=>state.user)
  const messagesEndRef = useRef(null);
  const tab = useSelector((state)=>state.app.tabs).find((tab)=>tab.id==id)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
      scrollToBottom();
    if(chatLog.length > 2){
    }
  }, [chatLog]);

  useEffect(() => {
    if(chatLog.length == 0){
      fetchChatHistory()
    }
  }, []);

  const fetchChatHistory = (e)=>{
    return
    setTimeout(()=>{
      const history = [{
        chatPrompt: "hi",
        botMessage: "This is ChatGPT"
      },{
        chatPrompt: "write a simple function in golang",
        botMessage: "Sure! Here's a simple function in Go that takes two integers as input and returns their sum:\n\n```\npackage main\n\nimport \"fmt\"\n\nfunc add(x int, y int) int {\n    return x + y\n}\n\nfunc main() {\n    fmt.Println(add(1, 2))\n}\n```\n\nIn this example, we create a function called `add` that takes two integers as input and returns their sum. In the main function, we call `add` with the arguments `1` and `2`, and print the result to the console using the `fmt.Println` function."
      }];
      setChatLog([...history,...chatLog]);
    },1000)
  }

  const handleSubmit = (inputPrompt,model) => {

    if(!inputPrompt || inputPrompt.length == 0){
      return;
    }

    setChatLog([...chatLog, { chatPrompt: inputPrompt }]);
    const history = []
    chatLog.map((chat)=>{
      history.push({
        role:"user",
        content:chat.chatPrompt
      });
      history.push({
        role:"assistant",
        content:chat.botMessage
      });
    })
    async function callAPI() {
      const messages = [
        {
          role:"system",
          content:"You are ChatGPT, powered by GPT-4, a large language model trained by OpenAI."
        },
        ...history,
        {
          role:"user",
          content: inputPrompt
      }];
      try {
        const response = await fetch("/api/chat", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body:JSON.stringify({
            messages,
            model
          })
        });
        const data = await response.json();
        dispatch(fetchUserAsync())
        setChatLog([
          ...chatLog,
          {
            chatPrompt: inputPrompt,
            botMessage: data.choices[0].message.content,
            usage:data.usage,
            blink:true
          },
        ]);
        setErr(false);
        if(tab.title == "New Chat"){
          dispatch(updateTabTitle(id,inputPrompt.slice(0,20)))
          async function callTitle(){
            const response = await fetch("/api/chat", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body:JSON.stringify({
                model:"gpt-3.5-turbo",
                messages:[{
                  role:"system",
                  content: "Please read the following exchange and write a short, concise title describing the topic within 8 words (in the user's language)."
                },{
                  role: "user",
                  content: "Message:\n\n" + inputPrompt + "\n\n" + data.choices[0].message.content + "\n\nTitle:"
                }]})
            });
            const res = await response.json();
            dispatch(updateTabTitle(id,res.choices[0].message.content))
          }
          callTitle()
        };

      } catch (err) {
        setErr(err);
      }
    }
    callAPI();

  };

  return (
    <div className="chat">
      <ChatBox>
        {chatLog.length > 0 ? (
          <div className="chatLogWrapper">
            {chatLog.length > 0 &&
              chatLog.map((chat, idx) => (
                <div className="chatLog" key={idx}>
                  <div className="chatPromptMainContainer">
                    <div className="chatPromptWrapper">
                       <Avatar size={36} color="blue">{user.email.toUpperCase().slice(0,2)}</Avatar>
                      <div id="chatPrompt"><p>{chat.chatPrompt}</p></div>
                    </div>
                  </div>

                  <div className="botMessageMainContainer">
                    <div className="botMessageWrapper">
                       <Avatar size={36} color="teal">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 42 42" fill="none" stroke="white" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                          {GPT}
                        </svg>
                      </Avatar>

                      {chat.botMessage ? (
                        <div id="botMessage" ref={messagesEndRef}>
                          <BotResponse blink={chat.blink} response={chat.botMessage.trim()} />
                        </div>
                      ) : err ? (
                        <Error err={err} />
                      ) : (
                        <Loading />
                      )}

                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <IntroSection setChatLog={(title,logs)=>{
            console.log("load",logs)
            if(title != ""){
              dispatch(updateTabTitle(id,title))
            }
            setChatLog(logs)
          }} />
        )}

      </ChatBox>
      <PromptBox id={id} chatLog={chatLog} closeChat={()=>{
        dispatch(closeTab(id))
      }} setChatLog={setChatLog} handleSubmit={handleSubmit}/>
    </div>
  );
}
