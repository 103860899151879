import { Dispatch } from "react";

/*
 * async Actions
export function fetchFiles(userDataPath: string) {
  return async function(dispatch: Dispatch<unknown>) {
    const configPath = path.join(
      userDataPath,
      "configurations"
    );
    if (!fs.existsSync(configPath)) {
      fs.mkdirSync(configPath);
    }
    const activeTunnelName = await getActiveTunnelName();
    const filenames = fs.readdirSync(configPath);
    const files = await Promise.all(
      filenames.map(async (filename: string) => {
        const filePath = path.join(configPath, filename);
        const config = new WgConfig({ filePath });
        await config.parseFile();

        const name = filename.split(".")[0];
        const lastConnectAt = localStorage.getItem(name);
        return {
          name,
          path: filePath,
          address: config.wgInterface.address,
          lastConnectAt,
          active: name === activeTunnelName,
        };
      })
    );

    dispatch(updateStatus(activeTunnelName));
    dispatch(fetchFilesSuccess(files));
  }
}
*/

export function createTab(id,title) {
  return {
    type: "create_tab",
    payload: {
      id,title
    },
  };
}

export function closeTab(id) {
  return {
    type: "close_tab",
    payload: {
      id
    },
  };
}

export function updateTabTitle(id,title) {
  return {
    type: "update_tab_title",
    payload: {
      id,title
    },
  };
}

export function setActiveTab(id) {
  return {
    type: "set_active_tab",
    payload: {
      id
    },
  };
}

export function updateTime(timestamp) {
  return {
    type: "update_time",
    payload: {
      timestamp
    },
  };
}

export function updateTimeAsync(timestamp) {
  return async function(dispatch) {
    setTimeout(()=>{
      dispatch(updateTime(timestamp))
    },60000)
  }
}


