import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {Link} from 'react-router-dom';
import axios from "axios";
import { useParams } from "react-router-dom"
import { Plus } from 'tabler-icons-react';


import {
  createStyles,
  Text,
  Title, TextInput, Button, Image,
  Grid,
  Tabs,
  Skeleton,
  Container,
  Alert,
  Paper,
  Stack,
  Badge,
  ActionIcon,
  Card, Table, Progress, Anchor, Group, ScrollArea } from '@mantine/core';

import {SimplePage}      from "../components/SimplePage/SimplePage"

import Chat from "../chat/Main.js"
import {createTab,updateTabTitle,setActiveTab} from "../store/modules/app/action.js"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const useWelcomeStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.xl * 2,
    borderRadius: theme.radius.md,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[3]
    }`,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: 'column-reverse',
      padding: theme.spacing.xl,
    },
  },

  image: {
    maxWidth: '40%',

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '100%',
    },
  },

  body: {
    paddingRight: theme.spacing.xl * 4,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      paddingRight: 0,
      marginTop: theme.spacing.xl,
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    marginBottom: theme.spacing.md,
  },

  control: {
    marginTop: theme.spacing.xl,
  },

  inputWrapper: {
    width: '100%',
    flex: '1',
  },

  input: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
  }

}));

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
  },

  button:{
    marginLeft: 2*theme.spacing.xs,
  },

  controlSection: {
    padding: theme.spacing.md,
    display: 'flex',
    alignItems: 'right',
    justifyContent: 'right',
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: -0.25,
    textTransform: 'uppercase',
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: 5,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },
}));

interface TableScrollAreaProps {
  data: { name: string; email: string; company: string }[];
}


export function Main({data}) {

  const dispatch = useDispatch()

  const { classes } = useStyles();
  //const params = useParams();
  const tabs = useSelector((state)=>state.app.tabs)
  const activeTab = useSelector((state)=>state.app.activeTab)

  return (
     <Tabs defaultValue={"tab_0"}  value={"tab_"+activeTab} onTabChange={(tab)=>{
        dispatch(setActiveTab(tab.split("_")[1]*1))
     }} >
      <Tabs.List>
        {
          tabs.map((tab)=>{ return (
            <Tabs.Tab key={tab.id} value={"tab_" + tab.id}>
              <span style={{
                fontFamily:"Söhne Bold, Söhne, -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif"
              }}>{tab.title}</span>
            </Tabs.Tab>
          )})
        }
        <Tabs.Tab onClick={(e)=>{
          const id = new Date().getTime();
          //setTabs(tabs.concat([{id}]))
          //setActiveTab("tab_"+ id)
          dispatch(createTab(id,"New Chat"))
          dispatch(setActiveTab(id))
        }} icon={<Plus size="1rem" />} value="money"/>
      </Tabs.List>

      {tabs.map((tab)=>{ return <Tabs.Panel key={tab.id} value={"tab_" + tab.id}><Chat id={tab.id}/></Tabs.Panel> })}

    </Tabs>
  );

}

export function Dashboard() {

  const { classes } = useStyles();
  const [ data,setData ] = useState(false);
  const user = useSelector((state)=>{return state.user})

  useEffect(()=>{
    /*
    if(user.user_id > 0){
      axios.get("/endpoints/list").then(({data})=>{
        setData(data.data.filter((item)=>{
          return item.status != "stop"
        }))
      })
    }
    */
  },[user])

  return (
      <Main data={[]}/>
  );
}
