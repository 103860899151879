import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import {Link} from 'react-router-dom';
import{
  Card,
  Group,
  Anchor,
  Image,
  Badge,
  Button,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
} from '@mantine/core';

import {SimplePage}      from "../components/SimplePage/SimplePage"
import {updateTimeAsync} from "../store/modules/app/action.js"


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Example() {

  return (
    <div>
        <p>Apart from the web, you can also use the GPTDOS API to access openai.</p>

        <h2><Anchor rel="noreferrer noopener"  href="https://chromewebstore.google.com/detail/gptdos-power-of-chatgpt-o/dobmhjbdlfbbiapckajjadbmclnoajij?hl=en-US&utm_source=ext_sidebar" target="_blank">GPTDOS Chrome Extenstion(new!)</Anchor></h2>

        <h2><Anchor rel="noreferrer noopener"  href="https://opencat.app" target="_blank">OpenCat</Anchor></h2>
        <h3>iOS/iPad</h3>

        <p><Link to="/console/api">API KEY</Link> And use the customerized  API domain</p>
        <h2><Anchor rel="noreferrer noopener"  href="https://chie.app/" target="_blank">Chie</Anchor></h2>
        <h3>Windows</h3>
        <p>Download <a href="https://gptdos.com/downloads/chieapp-v0.2.11-win32-x64.zip">X86</a></p>
        <p>GPTDOS API Settings: Create Assistant - Create new API credential - <Link to="/console/api">API KEY</Link> and use the customerized  API domain</p>
        <h3>Mac</h3>
        <p>Download <a href="https://gptdos.com/downloads/chieapp-v0.2.11-darwin-x64.zip">X86</a> | <a href="https://gptdos.com/downloads/chieapp-v0.2.11-darwin-arm64.zip">ARM</a> </p>
        <p>Create Assistant - Create new API credential - <Link to="/console/api">API KEY</Link> and use the customerized  API domain</p>
    </div>
  )
}


export function Clients() {
  const {current_time} = useSelector((state)=>state.app)
  const theme = useMantineTheme();
  const dispatch = useDispatch();

  const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];

  useEffect(()=>{
    const timestamp = new Date().getTime()
    dispatch(updateTimeAsync(timestamp));
    return ()=>{
      console.log("after effect")
    }
  },[current_time])

  return (
    <SimplePage data={{
      title:"Clients"
    }}>

    <Example/>

    </SimplePage>
  );
}

