import React, { useEffect, useState } from 'react';
import {Routes, Route, Redirect} from 'react-router-dom';
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import {
  ColorSchemeProvider,
  MantineProvider,
  LoadingOverlay
} from '@mantine/core';

import './App.css';
import store from "./store"
import {Shell} from "./components/Layout/shell.js"
import {AllRouters,ChatRouters,LandingRouters} from "./router.js"
import {fetchUserAsync} from "./store/modules/user/action.js"

axios.defaults.baseURL = '/api';
axios.interceptors.request.use((request) => {
  request.start = performance.now()
  return request
});

axios.interceptors.response.use((response) => {
  response.config.stop = performance.now();
  return response
});

/* Loading User Information
 * check user state
 * */
function UserProvider({children}) {
  const dispatch = useDispatch()
  const user = useSelector((state)=>state.user)
  const data = {user};
  const [visible, setVisible] = useState(true);

  useEffect(()=>{
    dispatch(fetchUserAsync())
  })

  useEffect(()=>{
    if(user.user_id > 0){
      setTimeout(()=>{
        setVisible(false)
      },800)
    }
    if(user.user_id == -1){
      setTimeout(()=>{
        window.location = "/"
      },800)
    }
  },[user])

  return (
    <>
        <LoadingOverlay  overlayBlur={1} overlayOpacity={1} overlayColor="#000" visible={visible} />
        <Shell mode="user" data={data} >
          {children}
        </Shell>
    </>
  )

};


export default function App() {
  const [colorScheme, setColorScheme] = useState('dark');
  const toggleColorScheme = (value) => {
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))
  }

  return (
    <BrowserRouter>
    <Provider store={store}>
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{ colorScheme }} withGlobalStyles withNormalizeCSS>
        <Routes >
          <Route path='/console/*' element={
              <UserProvider>
                <AllRouters/>
              </UserProvider>
          } />
          <Route path='/chat/*' element={
              <UserProvider>
                <ChatRouters/>
              </UserProvider>
          } />
          <Route index path='/*' element={
              <LandingRouters/>
          } />
         </Routes >
      </MantineProvider>
    </ColorSchemeProvider>
    </Provider>
    </BrowserRouter>
  );
}
