import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from "react-redux";
import { Ticket, AlertCircle, Messages, Database } from 'tabler-icons-react';
import {
  AppShell,
  Aside,
  Badge,
  Button,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  ActionIcon,
  useMantineColorScheme,
  ThemeIcon,
  UnstyledButton,
  Box
} from '@mantine/core';

import useStyles from './layout.styles';
import { Nav } from "./nav.js";
import { Header } from "./header.js";

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #192839;
    color: white;
`;

export function Shell({children,mode,data}) {

  const theme = useMantineTheme();
  const { classes, cx } = useStyles({shouldRenderHeader:true});
  const [opened, setOpened] = useState(false);

  return (
    <Container>
    <Header data={data}></Header>
    {children}
    </Container>
  )
}

/*
    <AppShell
      className={classes.main}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      navbar={<Nav opened={opened} data={data} />}
      padding={0}
      aside={null}
      header={false}
    >
    </AppShell>
*/
