import React from 'react';
import {Routes, Route, Redirect} from 'react-router-dom';

import {Demo} from './pages/demo.js';
import {Dashboard} from './pages/dashboard.js';
import {User} from './pages/user.js';
import {Account} from './pages/account.js';
import {Login} from './pages/login.js';
import {Join} from './pages/join.js';
import {Code} from './pages/code.js';
import {Refer} from './pages/refer.js';

import {Team} from './pages/team.js';
import {Prompts} from './pages/prompts.js';
import {Api} from './pages/api.js';
import {Clients} from './pages/clients.js';

/*
import {Invite} from './pages/invite.js';
<Route path='/team/invite' element={<Invite />} />
*/

export function AllRouters(){

  return (
    <div>
    <Routes >
        <Route path='/team' element={<Team />} />
        <Route path='/code' element={<Code />} />
        <Route path='/user' element={<User />} />
        <Route path='/points' element={<Account />} />
        <Route path='/refer' element={<Refer />} />
        <Route path='/prompts' element={<Prompts />} />
        <Route path='/api' element={<Api />} />
        <Route path='/clients' element={<Clients />} />
        <Route path='/*' element={<Demo />} />
    </Routes>
    </div>
  )

}

export function ChatRouters(){
  return (
    <div>
      <Routes >
        <Route path='/:id' element={<Dashboard />} />
        <Route path='/*' element={<Dashboard />} />
      </Routes>
    </div>
  )
}

export function LandingRouters(){
  return (
    <Routes >
        <Route index path='/' element={<Login />} />
        <Route path='/join/:auth' element={<Join />} />
        <Route path='*' element={<Login />} />
    </Routes>
  )
}
