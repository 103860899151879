import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Switch } from '@headlessui/react'
import axios from "axios";
import {Link} from 'react-router-dom';

import{
  Card,
  Anchor,
  Group,
  Image,
  Badge,
  Button,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
} from '@mantine/core';

import {SimplePage}      from "../components/SimplePage/SimplePage"
import {updateTimeAsync} from "../store/modules/app/action.js"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function User() {
  const {current_time} = useSelector((state)=>state.app)
  const theme = useMantineTheme();
  const user = useSelector((state)=>state.user)

  const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];

  function Logout(){
    axios.post("/logout",{}).then(()=>{
      window.location = "/"
    })
  }

  return (
    <SimplePage  data={{
      title:"Profile"
    }}>
    <p>Email: {user.email}</p>
    <p>Points: <Anchor component={Link} to="/console/points" rel="noreferrer noopener">
          {user.points_available}(Buy)
        </Anchor>
    </p>
    <p>
        <Anchor  href="https://t.me/+UaruQTsqrcNkMWNl" rel="noreferrer noopener" target="_blank">
            Telegram Group
        </Anchor>
    </p>
    <p>
        <Anchor component={Link} to="/console/prompts" rel="noreferrer noopener">
            My Prompts
        </Anchor>
    </p>
    <p>
        <Anchor component={Link} to="/console/api">
          API
        </Anchor>
    </p>
    <p>
        <Anchor component={Link} to="/console/clients">
          Clients
        </Anchor>
    </p>
    <p>
        <Anchor component={Link} to="/console/refer">
            Refer Links
        </Anchor>
    </p>
    <Button variant="light" onClick={()=>{Logout()}} >Logout</Button>

    </SimplePage>
  );
}

