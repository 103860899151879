import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Card,Group,Text } from '@mantine/core';
import { Markdown } from './Markdown.jsx';

const Container = styled.div`
    .content {
        margin-top: 0rem;
        max-width: 100%;
        * {
            color: white;
        }
        p, ol, ul, li, h1, h2, h3, h4, h5, h6, img, blockquote, &>pre {
            max-width: 50rem;
            margin-left: auto;
            margin-right: auto;
        }
        img {
            display: block;
            max-width: 50rem;
            @media (max-width: 50rem) {
                max-width: 100%;
            }
        }

        .block{
          border-radius:0px !important;
        }

        ol {
            counter-reset: list-item;
            li {
                counter-increment: list-item 1;
            }
        }
        ul {
            counter-reset: list-item;
            li {
                counter-increment: list-item 1;
            }
        }
        em, i {
            font-style: italic;
        }
        code {
            &, * {
              font-family:"Söhne Mono";
            }
            vertical-align: bottom;
        }
        /* Tables */
        table {
            margin-top: 1.618rem;
            border-spacing: 0px;
            border-collapse: collapse;
            border: thin solid #444;
            rgba(255, 255, 255, 0.1);
            width: 100%;
            max-width: 55rem;
            margin-left: auto;
            margin-right: auto;
        }
        td + td, th + th {
            border-left: thin solid rgba(255, 255, 255, 0.1);
        }
        tr {
            border-top: thin solid rgba(255, 255, 255, 0.1);
        }
        table td,
        table th {
            padding: 0.618rem 1rem;
        }
        th {
            font-weight: 600;
            background: rgba(255, 255, 255, 0.1);
        }
    }
    .metadata {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-family: "Work Sans", sans-serif;
        font-size: 0.8rem;
        font-weight: 400;
        opacity: 0.6;
        max-width: 50rem;
        margin-bottom: 0.0rem;
        margin-right: -0.5rem;
        margin-left: auto;
        margin-right: auto;
        span + span {
            margin-left: 1em;
        }
        .fa {
            font-size: 85%;
        }
        .fa + span {
            margin-left: 0.2em;
        }
        .mantine-Button-root {
            color: #ccc;
            font-size: 0.8rem;
            font-weight: 400;
            .mantine-Button-label {
                display: flex;
                align-items: center;
            }
        }
    }
    .fa {
        margin-right: 0.5em;
        font-size: 85%;
    }
    .buttons {
        text-align: right;
    }
    strong {
        font-weight: bold;
    }
`;

const BotResponse = ({ response,blink }) => {
  const [botResponse, setBotResponse] = useState("");
  const containerRef = useRef(null);

  const scrollToBottom = () => {
    if(blink){
      containerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  if(blink){
    useEffect(() => {
      let index = 1;
      let msg = setInterval(() => {
        setBotResponse(response.slice(0, index));
        if (index >= response.length) {
          clearInterval(msg);
        }
        index = index + Math.random()*20>>0;
      },80);
    }, [response]);
  }else{
    useEffect(() => {
      setBotResponse(response)
      scrollToBottom();
    }, []);
  }

  useEffect(() => {
    if(botResponse == response){
      scrollToBottom();
    }
  }, [botResponse]);

  return (
    <Container  ref={containerRef}>
      <Markdown content={botResponse + (botResponse === response ? "" : "█")} className={"content"}/>
    </Container>
  );

  /*
  return (
    <Container  ref={containerRef}>
      <Markdown content={response} className={"content"}/>
    </Container>
  );

  */

  return (
    <div ref={containerRef}>
        { (botResponse + (botResponse === response ? "" : "█") ).split("```").map((text,i)=>{
          if(i % 2 == 0){
            return <p key={i}>{text}</p>
          }else{
            return (
              <Card key={i} shadow="sm" padding="xs" radius="md" withBorder>
               <Card.Section withBorder inheritPadding py="xs">
               <Group position="apart">
                  <Text weight={500}>Code</Text>
                </Group>
              <SyntaxHighlighter
              wrapLongLines={true}
              wrapLines={true}
              lineProps={lineNumber => ({
                style: { display: 'block', cursor: 'pointer' },
                onClick() {
                  console.log(`Line Number Clicked: ${lineNumber}`);
                }
              })}
              style={a11yDark}
              >
              {text}
            </SyntaxHighlighter>
              </Card.Section>
              </Card>
            )
          }
        })}
    </div>
  );
};

export default BotResponse;
