import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link,Navigate,useParams } from 'react-router-dom';
import { Code,Grid } from '@mantine/core';
import axios from 'axios';

import {
  useViewportSize,
  useElementSize,
  useDisclosure
} from '@mantine/hooks';

import{
  Alert,
  Card,
  Modal,
  Tabs,
  Group,
  Image,
  Badge,
  Container,
  Button,
  Text,
  Title,
  Paper,
  Table,
  MediaQuery,
  createStyles,
  Burger,
  useMantineTheme,
  TextInput,
  Textarea,
  ScrollArea,
  rem
} from '@mantine/core';

import {SimplePage}      from "../components/SimplePage/SimplePage"
import {updateTimeAsync} from "../store/modules/app/action.js"

const useStyles = createStyles((theme) => ({
  card: {
    width:"100%",
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
  },

  table:{
    width:"100%",
  },

  header: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background:"#333",
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `${rem(1)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },

  headerSection: {
    padding: theme.spacing.xl,
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  controlSection: {
    padding: theme.spacing.xl,
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: -0.25,
    textTransform: 'uppercase',
  },

  section: {
    padding: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: 5,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },
}));

function Example() {
  const demo = `curl -v https://api.gptdos.com/v1/chat/completions \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Bearer sk-gpt-your-own-api-secret-key-here" \\
  -d '{
    "model": "gpt-3.5-turbo",
    "stream":true,
    "messages": [
      {
        "role": "system",
        "content": "You are a helpful assistant."
      },
      {
        "role": "user",
        "content": "Hello!"
      }
    ]
  }'`;
  return <Code block>{demo}</Code>;
}

export function Api() {

  const [activeTab, setActiveTab] = useState('members');

  const { ref, width, height } = useElementSize();

  return (
    <div ref={ref}>
      <SimplePage  data={{
        title:"API"
      }}>

    <Grid>
      <Grid.Col span="auto">
        <p>
        Simple alternative openai api with stream support.
        <br/>
        <br/>

        API Domain: api.gptdos.com<br/>
        API Endpoint: https://api.gptdos.com<br/>
        Completions API: https://api.gptdos.com/v1/chat/completions<br/>
        Supported <Link to="/console/clients">Clients</Link><br/><br/>
        </p>
      </Grid.Col>
      <Grid.Col span="auto">
        <Example/>
      </Grid.Col>
    </Grid>
    <KeyList height={height} width={width} id={"1"} update={()=>{}}/>
    </SimplePage>
    </div>
  )
}

export function Create({onCreated}) {

 const { classes }    = useStyles();

 const [opened, { open, close }] = useDisclosure(false);
 const [title,setTitle] = useState("")
 const [content,setContent] = useState("")
 const [error,setError] = useState(false)

 const dispatch = useDispatch();

 async function handleSubmit(name){
     if(name.length < 3){
        return setError("name too short")
     }
     if(name.length > 20){
        return setError("name too long")
     }
     try {
       const response = await fetch("/api/keys", {
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body:JSON.stringify({name,id:0,action:"create"})
       });
       const data = await response.json();
       console.log(data)
       onCreated()
       close()
     }catch(e){
     }
 }

  const ph = "opencat"

  return (
    <> 
      <Modal size={"xl"} p={10} opened={opened} onClose={close} title="API Key">
          {error?
          <Alert title="Error!" color="red">
              {error}
          </Alert>
          :null}
             <TextInput value={title} onChange={(e)=>{
               if(error){setError(false)}
               setTitle(e.target.value)
             }} size={"lg"} label="Name" placeholder={ph}></TextInput>
             <br/>
             <Group position="apart" mt="lg" className={classes.controls}>
               <Button onClick={(e)=>{
                 handleSubmit(title)
               }} className={classes.control}>Create</Button>
             </Group>
      </Modal>
      <Group position="center">
        <Button onClick={open}>New API Key</Button>
      </Group>
    </>
  );
}

function KeyList({id,update,height,width}) {

  const { classes,cx } = useStyles();
  const [reload, setReload]       = useState(new Date().getTime());
  const [keys, updateKeys]  = useState([]);
  const [scrolled, setScrolled] = useState(false);

  useEffect(()=>{

    async function getList(){
      const response = await fetch("/api/keys", {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      });
      const data = await response.json();
      updateKeys(data.data.keys)
    }

    getList()

    return ()=>{
      console.log("after effect")
    }
  },[reload,update])

  async function deleteKey({id}){
    const response = await fetch("/api/keys", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body:JSON.stringify({id,action:"delete"})
    });
    const data = await response.json();
    setReload(new Date().getTime())
  }

  return (
    <Card withBorder mt={20} radius="md" className={classes.card}>
      <Card.Section className={classes.headerSection}>
        <Group position="apart" mt="md" mb="xs">
          <Create onCreated={() => {
            setReload(new Date().getTime())
          }} />
        </Group>
      </Card.Section>

      <Table className={classes.table} miw={700}>
        <thead className={cx(classes.header, { [classes.scrolled]: false })}>
        </thead>
        <tbody>
          {keys.map((item,i)=>{
            return (
              <tr key={i}>
                <td>
                {item.name}
                </td>
                <td>
                {item.value}
                </td>
                <td>
                  <Button variant="outline" onClick={()=>{deleteKey(item)}} >DEL</Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      <Card.Section className={classes.section} mt="md">
        <Text size="sm" color="dimmed" className={classes.label}>
          API Secret Keys
        </Text>
      </Card.Section>

    </Card>
  )

}






