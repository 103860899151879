import React from "react";
import styled from '@emotion/styled';

const Blink = styled.div`
  color: #fff;
  display: inline;
  -webkit-animation: 1.4s blink step-end infinite;
  -moz-animation: 1.3s blink step-end infinite;
  -ms-animation: 1.3s blink step-end infinite;
  -o-animation: 1.3s blink step-end infinite;
  animation: 1.3s blink step-end infinite;

@keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: #fff;
  }
}

@-moz-keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #fff;
  }
}

@-webkit-keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: #fff;
  }
}

@-ms-keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: #fff;
  }
}

@-o-keyframes "blink" {
  from, to {
    color: transparent;
  }
  50% {
    color: #fff;
  }
}
`

const Loading = () => {
  return (
      <Blink>█</Blink>
  );
};

export default Loading;
