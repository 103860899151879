import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link,Navigate,useParams } from 'react-router-dom';
import axios from 'axios';

import { useDisclosure } from '@mantine/hooks';

import{
  Card,
  Modal,
  Tabs,
  Group,
  Image,
  Badge,
  Container,
  Button,
  Text,
  Title,
  Paper,
  Table,
  MediaQuery,
  createStyles,
  Burger,
  useMantineTheme,
  TextInput,
  Textarea,
} from '@mantine/core';

import {SimplePage}      from "../components/SimplePage/SimplePage"
import {updateTimeAsync} from "../store/modules/app/action.js"

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
  },

  controlSection: {
    padding: theme.spacing.xl,
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: -0.25,
    textTransform: 'uppercase',
  },

  section: {
    padding: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: 5,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },
}));

function Controller(){

  const { classes } = useStyles();
  return (
    <Card withBorder radius="md" className={classes.card}>
      <Card.Section className={classes.controlSection}>
        Endpoint controller
      </Card.Section>

      <Group position="apart" mt="md"></Group>

      <Card.Section className={classes.section} mt="md">
        <Text size="sm" color="dimmed" className={classes.label}>
          Basic configuration
        </Text>

        <Group spacing={8} mb={-8}>
          This is a test part
        </Group>
      </Card.Section>

    </Card>
  )

}

export function Endpoint() {
  let { id } = useParams();
  const {current_time} = useSelector((state)=>state.app)
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const [reload, setReload]       = useState(new Date().getTime());
  const [sources, setSources]    = useState("");
  const [error, updateError]     = useState(false);
  const [redirect, setRedirect]  = useState(false);
  const [endpoint, setEndpoint]  = useState({});

  const { classes } = useStyles();

  const updateSources = (config)=>{
    const servers = {};
    const list = config
      .split("\n")
      .filter((i)=>{return i.length > 0})
      .map((row)=>{
        const values = row.split(" ").filter((i)=>{return i.indexOf("=") != -1});
        console.log(values,row)
        const object = {}
        values.map((item)=>{
          const [key,value] = item.split("=")
          object[key] = value
        })
        return object
      });

     setSources(list)
  }

  const onImport = (e)=>{

    axios.post("/endpoint/" + id + "/import",{
      endpoint:{
        backends:sources,
      }
    }).then(({data})=>{
      console.log(JSON.stringify(data,null,2))
      setReload(new Date().getTime())
    })

  }

  const onDelete = (e)=>{
    e.preventDefault()
    axios.post("/endpoint/" + id + "/update",{
      endpoint:{
        status:"stop"
      }
    }).then(({data})=>{
      setRedirect("/console")
    })
  }

  const changeSources = (e)=>{
    const value = e.target.value;
    //const rows  = value.split("\n");
    //check sources
    updateSources(value)
  }

  const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];

  useEffect(()=>{

    axios.get("/endpoint/" + id + "/settings").then(({data})=>{
      //window.location.reload()
    })

    return ()=>{
      console.log("after effect")
    }
  },[reload])

  return (
    <SimplePage data={{
      title:"Endpoint"
    }}>
    {redirect?<Navigate to={redirect} replace={true}/>:null}

    <br/>

    <Card withBorder radius="md" className={classes.card}>
      <Card.Section className={classes.controlSection}>
        Endpoint controller
      </Card.Section>

      <Group  mt="md">
                  <Textarea
                    onChange={(e)=>{updateSources(e.target.value)}}
                    autosize
                    minRows={10}
                    maxRows={20}
                    sx={{ minWidth: 400 }}
                    placeholder="host=1.1.1.1 port=3389 priority=100"
                    defaultValue={sources}
                  />
      </Group>
      <p>Example</p>
      <p>host=1.1.1.1 port=3389 priority=100</p>

      <Group  mt="md">
                  <Button
                    variant="outline"
                    onClick={(e)=>{onImport(e)}}
                  >
                    Import
                  </Button>
                <p className="mt-2 text-sm text-gray-500">{error?error:null}</p>

      </Group>
      <Group>
                  <Button
                    mt={20}
                    variant="outline"
                    onClick={(e)=>{onDelete(e)}}
                  >
                    Delete
                  </Button>
      </Group>
    </Card>

    </SimplePage>
  )

}



export function Team() {

  const [activeTab, setActiveTab] = useState('members');

  return (
    <SimplePage data={{
      title:"Team"
    }}>
        <Tabs value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="members">Members</Tabs.Tab>
            <Tabs.Tab value="pending">Pending</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="members" style={{padding:"1rem"}}>
            <Members id={"1"} update={()=>{}}/>
          </Tabs.Panel>
          <Tabs.Panel value="pending">
          </Tabs.Panel>

        </Tabs>
    </SimplePage>
  )
}


function Demo() {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Modal opened={opened} onClose={close} title="Invite Members" centered>
        <Invite/>
      </Modal>

      <Button onClick={open}>Invite</Button>
    </>
  );
}

export function Invite() {

 const { classes }    = useStyles();
 const [email,setEmail] = useState("wang@company.com")
 const dispatch = useDispatch();

  const [label,setLabel] = useState("Email to Invite")

  async function handleSubmit(email){
      try {
        const response = await fetch("/api/user/invite", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body:JSON.stringify({email})
        });
        const data = await response.json();
        console.log(data)
      }catch(e){
      }
  }

  return (
     <Container size={600} my={20}>
      <Title className={classes.title} align="center">
        Invite Team Member
      </Title>
      <Text c="dimmed" fz="sm" ta="center">
        Email you want to invite
      </Text>

      <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
        <TextInput value={email} onChange={(e)=>{
          setLabel("Your email")
          setEmail(e.target.value)
        }} label={label} placeholder="wang@company.com" />
        <Group position="apart" mt="lg" className={classes.controls}>
          <Button onClick={(e)=>{
            handleSubmit(email)
          }} className={classes.control}>Send Email</Button>
        </Group>
      </Paper>
    </Container>
  );
}


function Members({id,update}) {

  const { classes } = useStyles();
  const [reload, setReload]       = useState(new Date().getTime());
  const [members, updateMembers]  = useState([]);

  useEffect(()=>{

    updateMembers([{
      email:"xxx@gmail.com",
      status:"binding",
      tokens:10000
    },{
      email:"xxx@gmail.com",
      status:"binding",
      tokens:10000
    }])
    /*
    axios.get("/endpoint/" + id + "/settings").then(({data})=>{
      updateBackends(data.data.backends)
    })
    */

    return ()=>{
      console.log("after effect")
    }
  },[reload,update])

  const deleteBackend = (backend)=>{
    console.log("deleteBackend",backend)
    axios.post("/endpoint/" + backend.id + "/backend",{
      action:"delete",
      backend_id:backend.id
    }).then(({data})=>{
      setReload(new Date().getTime())
    })
  }

  return (
    <Card withBorder radius="md" className={classes.card}>
      <Card.Section className={classes.controlSection}>
        Members
      </Card.Section>

      <Group position="apart" mt="md">

     <Table sx={{ minWidth: 700 }}>
        <thead>
          <tr>
            <th>Member</th>
            <th>Date</th>
            <th>Tokens</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {members.map((backend,i)=>{
            return (
              <tr key={i}>
                <td>
                {backend.email}
                </td>
                <td>
                {backend.status}
                </td>
                <td>
                {backend.tokens}
                </td>
                <td>
                  <Button variant="outline" onClick={()=>{deleteBackend(backend)}} >Fund</Button>
                  <Button variant="outline" onClick={()=>{deleteBackend(backend)}} >DEL</Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      </Group>

      <Card.Section className={classes.section} mt="md">
        <Text size="sm" color="dimmed" className={classes.label}>
          Invite Members
        </Text>
        <Group spacing={8} mb={-8}>
          <Demo/>
        </Group>
      </Card.Section>

    </Card>
  )

}






