import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link,Navigate,useParams } from 'react-router-dom';
import axios from 'axios';

import {
  useViewportSize,
  useElementSize,
  useDisclosure
} from '@mantine/hooks';

import{
  Alert,
  Card,
  Modal,
  Tabs,
  Group,
  Image,
  Badge,
  Container,
  Button,
  Text,
  Title,
  Paper,
  Table,
  MediaQuery,
  createStyles,
  Burger,
  useMantineTheme,
  TextInput,
  Textarea,
  ScrollArea,
  rem
} from '@mantine/core';

import {SimplePage}      from "../components/SimplePage/SimplePage"
import {updateTimeAsync} from "../store/modules/app/action.js"

const useStyles = createStyles((theme) => ({
  card: {
    width:"100%",
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
  },

  table:{
    width:"100%",
  },

  header: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    background:"#333",
    transition: 'box-shadow 150ms ease',

    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottom: `${rem(1)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
      }`,
    },
  },

  scrolled: {
    boxShadow: theme.shadows.sm,
  },

  headerSection: {
    padding: theme.spacing.xl,
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  controlSection: {
    padding: theme.spacing.xl,
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'left',
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: -0.25,
    textTransform: 'uppercase',
  },

  section: {
    padding: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: 5,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },
}));

export function Prompts() {

  const [activeTab, setActiveTab] = useState('members');

  const { ref, width, height } = useElementSize();

  return (
    <div ref={ref}>
      <SimplePage  data={{
        title:"Prompts"
      }}>
        <PromptList height={height} width={width} id={"1"} update={()=>{}}/>
      </SimplePage>
    </div>
  )
}

export function Create({onCreated}) {

 const { classes }    = useStyles();

 const [opened, { open, close }] = useDisclosure(false);
 const [title,setTitle] = useState("")
 const [content,setContent] = useState("")
 const [error,setError] = useState(false)

 const dispatch = useDispatch();

 async function handleSubmit(title,content){
     if(title.length < 3 || content.length < 10){
        return setError("too short")
     }
     if(title.length > 20 || content.length > 100){
        return setError("too long")
     }
     try {
       const response = await fetch("/api/prompts", {
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body:JSON.stringify({title,content,id:0,action:"create"})
       });
       const data = await response.json();
       console.log(data)
       onCreated()
       close()
     }catch(e){
     }
 }

  const ph = "Summarize to the most important points."
  const pc = `Summarize the text below as a bullet point list of the most important points.
Text: """
{paste your text input here}
""""
  `
  return (
    <> 
      <Modal size={"xl"} p={10} opened={opened} onClose={close} title="Prompt">
          {error?
          <Alert title="Error!" color="red">
              {error}
          </Alert>
          :null}
             <TextInput value={title} onChange={(e)=>{
               if(error){setError(false)}
               setTitle(e.target.value)
             }} size={"lg"} label="Title" placeholder={ph}></TextInput>
             <br/>
            <Textarea
              value={content}
              onChange={(e)=>{
                if (error) { setError(false) }
                setContent(e.target.value)
              }}
              placeholder={pc}
              label="Prompt"
              minRows={10}
              maxRows={20}
              size={"lg"}
            />
             <Group position="apart" mt="lg" className={classes.controls}>
               <Button onClick={(e)=>{
                 handleSubmit(title,content)
               }} className={classes.control}>Save</Button>
             </Group>
      </Modal>
      <Group position="center">
        <Button onClick={open}>New Prompt Template</Button>
      </Group>
    </>
  );
}

export function Edit({prompt,onEdit}) {

 const { classes }    = useStyles();

 const [opened, { open, close }] = useDisclosure(false);
 const [title,setTitle] = useState(prompt.title)
 const [content,setContent] = useState(prompt.content)
 const [error,setError] = useState(false)

 const dispatch = useDispatch();

 async function handleSubmit(title,content){

     if(title.length < 3 || content.length < 10){
        return setError("too short")
     }

     if(title.length > 20 || content.length > 100){
        return setError("too long")
     }

     try {
       const response = await fetch("/api/prompts", {
         method: "POST",
         headers: { "Content-Type": "application/json" },
         body:JSON.stringify({title, content, id:prompt.id, action:"update"})
       });
       const data = await response.json();
       console.log(data)
       onEdit()
       close()
     }catch(e){
     }
 }

  return (
    <> 
      <Modal size={"xl"} opened={opened} onClose={close} title="Prompt">
          {error?
          <Alert title="Error!" color="red">
              {error}
          </Alert>
          :null}
             <TextInput value={title} onChange={(e)=>{
               if(error){setError(false)}
               setTitle(e.target.value)
             }} size={"lg"} label="Title" placeholder={title} />
            <Textarea
              value={content}
              onChange={(e)=>{setContent(e.target.value)}}
              placeholder={content}
              label="Prompt"
              minRows={10}
              maxRows={20}
              size={"lg"}
            />
             <Group position="apart" mt="lg" className={classes.controls}>
               <Button onClick={(e)=>{
                if(error){setError(false)}
                 handleSubmit(title,content)
               }} className={classes.control}>Save</Button>
             </Group>
      </Modal>
      <Button variant="outline" onClick={open}>Edit</Button>
    </>
  );
}



function PromptList({id,update,height,width}) {

  const { classes,cx } = useStyles();
  const [reload, setReload]       = useState(new Date().getTime());
  const [prompts, updatePrompts]  = useState([]);
  const [scrolled, setScrolled] = useState(false);

  useEffect(()=>{

    async function getList(){
      const response = await fetch("/api/prompts", {
        method: "GET",
        headers: { "Content-Type": "application/json" }
      });
      const data = await response.json();
      updatePrompts(data.data.prompts)
    }

    getList()

    return ()=>{
      console.log("after effect")
    }
  },[reload,update])

  async function deletePrompt({id}){
    const response = await fetch("/api/prompts", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body:JSON.stringify({id,action:"delete"})
    });
    const data = await response.json();
    setReload(new Date().getTime())
  }

  return (
    <Card withBorder mt={20} radius="md" className={classes.card}>
      <Card.Section className={classes.headerSection}>
        <Group position="apart" mt="md" mb="xs">
          <Create onCreated={() => {
            setReload(new Date().getTime())
          }} />
        </Group>
      </Card.Section>

      <ScrollArea mt={10} h={height - 370} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
      <Table className={classes.table} miw={700}>
        <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
          <tr>
            <th>Title</th>
            <th>Content</th>
            <th style={{width:"10rem"}}>Action</th>
          </tr>
        </thead>
        <tbody>
          {prompts.map((item,i)=>{
            return (
              <tr key={i}>
                <td>
                {item.title.slice(0,10)}
                </td>
                <td>
                {item.content.slice(0,100)}
                </td>
                <td>
                  <Edit onEdit={()=>{
                    setReload(new Date().getTime())
                  }} prompt={item}/>
                  {" "}
                  <Button variant="outline" onClick={()=>{deletePrompt(item)}} >DEL</Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      </ScrollArea>

      <Card.Section className={classes.section} mt="md">
        <Text size="sm" color="dimmed" className={classes.label}>
          Advanced Prompt Templates
        </Text>
      </Card.Section>

    </Card>
  )

}






