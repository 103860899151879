import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import {fetchUserAsync} from "../store/modules/user/action.js"
import{
  Card,
  Group,
  Image,
  Badge,
  Button,
  Paper,
  TextInput,
  Anchor,
  Center,
  createStyles,
  Container,
  Title,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
} from '@mantine/core';

import {SimplePage}      from "../components/SimplePage/SimplePage"


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: "2.6rem",
    fontWeight: 900,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  controls: {
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column-reverse',
    },
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
}));

export function Code() {
  /*
  const {current_time} = useSelector((state)=>state.app)
  */
 const { classes }    = useStyles();
 const [code,setCode] = useState("")
 const dispatch = useDispatch();

  const [label,setLabel] = useState("Your code")

  async function handleSubmit(code){
      try {
        const response = await fetch("/api/code", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body:JSON.stringify({code})
        });
        const data = await response.json();
        if(data.code == 1){
          setLabel("代码错误")
        }
        if(data.code == 2){
          setLabel("代码已经使用")
        }
        if(data.code == 0){
          setLabel("成功")
          dispatch(fetchUserAsync())
        }
      }catch(e){
      }
  }

  return (
    <SimplePage data={{ }}>
     <Container size={600} my={20}>
      <Title className={classes.title} align="center">
        Got a redeem Code?
      </Title>
      <Text c="dimmed" fz="sm" ta="center">
        Enter your code to get extra tokens
      </Text>

      <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
        <TextInput value={code} onChange={(e)=>{
          setLabel("Your code")
          setCode(e.target.value)
        }} label={label} placeholder="xxx-xxxxxxx-xxxxx" />
        <Group position="apart" mt="lg" className={classes.controls}>
          <Button onClick={(e)=>{
            handleSubmit(code)
          }} className={classes.control}>Enter</Button>
        </Group>
      </Paper>
    </Container>

    </SimplePage>
  );
}

