import { Dispatch } from "react";
import axios from "axios";

/*
 * async Actions
export function fetchFiles(userDataPath: string) {
  return async function(dispatch: Dispatch<unknown>) {
    const configPath = path.join(
      userDataPath,
      "configurations"
    );
    if (!fs.existsSync(configPath)) {
      fs.mkdirSync(configPath);
    }
    const activeTunnelName = await getActiveTunnelName();
    const filenames = fs.readdirSync(configPath);
    const files = await Promise.all(
      filenames.map(async (filename: string) => {
        const filePath = path.join(configPath, filename);
        const config = new WgConfig({ filePath });
        await config.parseFile();

        const name = filename.split(".")[0];
        const lastConnectAt = localStorage.getItem(name);
        return {
          name,
          path: filePath,
          address: config.wgInterface.address,
          lastConnectAt,
          active: name === activeTunnelName,
        };
      })
    );

    dispatch(updateStatus(activeTunnelName));
    dispatch(fetchFilesSuccess(files));
  }
}
*/

export function updateUser(data) {
  return {
    type: "user_update",
    payload: data
  };
}

export function fetchUserAsync() {
  return async function(dispatch) {
    axios.get("/user/info").then(({data})=>{

      if(data.data){
        dispatch(updateUser(data.data))
      }else{
        dispatch(updateUser({
          userInfo:{
            id:-1,
            email:"...",
            vip:0,
            points_available:0
          }
        }))
      }
    })
  }
}
