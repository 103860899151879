import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Switch } from '@headlessui/react'
import axios from "axios";
import {SimplePage}      from "../components/SimplePage/SimplePage"
import {Link,useNavigate,useLocation} from 'react-router-dom';

import {
  useViewportSize,
  useElementSize,
  useDisclosure
} from '@mantine/hooks';

import{
  Card,
  Group,
  Image,
  Badge,
  Button,
  Text,
  MediaQuery,
  Burger,
  ScrollArea,
  Table,
  createStyles,
  useMantineTheme,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  progressBar: {
    '&:not(:first-of-type)': {
      borderLeft: `3px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
    },
  },
}));




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function Account() {
  const {current_time} = useSelector((state)=>state.app)
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const user = useSelector((state)=>state.user)
  const navigate = useNavigate()
  const { ref, width, height } = useElementSize();

  const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];

  const [balances,setBalances] = useState([]);

  useEffect(()=>{
    axios.get("/user/balance").then((res)=>{
      const balances = res.data.data;
      setBalances(balances)
    })
  },[])

  const requestOrder = async (points) => {
    axios.post("/user/shop/request",{
      points
    }).then((res)=>{
      window.location =  res.data.url
    })

    /*
    axios.post("/user/shop/alpha",{
      tokens
    }).then((res)=>{
      window.location =  res.data.url
    })
    */

  }


  return (
    <SimplePage  data={{
      title:"Points"
    }}>

      <p>Your Points are used for accessing our ChatGPT API. Each API call consumes a certain amount of Tokens/Points.</p>
      <p>
        For english conversation: 1-2 sentence ~= 30 tokens, 1 paragraph ~= 100 tokens, 1500 words ~= 2048 tokens
      </p>
      <p>When using GPT3.5, the API consumes 1 Points for every 1 OpenAI token used.</p>
      <p>When using GPT-4, the API consumes 10 Points for every 1 OpenAI token used.</p>
      <p>The conversation by default is sent with the context, your whole chat history, which significantly increases token consumption. You can click on "Reset Conversation" to clear the context.</p>
      <p> As of now, OpenAI has set a limit of 100 daily API calls for GPT-4 Turbo. Therefore, we are currently unable to offer this specific model. We will keep an eye on OpenAI's usage limits and pricing adjustments.</p>

      <Group>
        <Button onClick={()=>{requestOrder(200000)}}>19RMB For 200K Points</Button>
        <Button onClick={()=>{requestOrder(500000)}}>39RMB For 500K Points</Button>
        <Button onClick={()=>{requestOrder(900000)}}>59RMB For 900K Points</Button>
        <Button onClick={()=>{requestOrder(2000000)}}>99RMB For 2000K Points</Button>
      </Group>

      <ScrollArea mt={10} h={ 500} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
        <Balances data={balances}/>
      </ScrollArea>

    </SimplePage>
  );

}

export function Balances({ data }) {
  const { classes, theme } = useStyles();

  const rows = data.map((row) => {

    const date = new Date(row.time).toLocaleString();

    return (
      <tr key={row.id}>
        <td>
            {date}
        </td>
        <td>
          {row.business}
        </td>
        <td>
          {row.detail}
        </td>
        <td>
          {(row.balance*1).toFixed(0)}
        </td>
        <td>
          <Group position="apart">
            {row.change>0?
            <Text size="xs" color="teal" weight={700}>{(row.change*1).toFixed(0)}</Text>
              :
            <Text size="xs" color="red" weight={700}>{(row.change*1).toFixed(0)}</Text>
            }
          </Group>
        </td>
      </tr>
    );
  });

  return (
    <ScrollArea mt={20}>
      <Table sx={{ minWidth: 800 }} verticalSpacing="xs">
        <thead>
          <tr>
            <th>Date</th>
            <th>Business</th>
            <th>Action</th>
            <th>Points</th>
            <th>Change</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}


