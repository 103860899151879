import { produce } from "immer";
import { AnyAction } from "redux";

const defaultState = {
  user_id:0,
  email:"",
  vip:0
};

export default function (state = defaultState, action: AnyAction) {
  return produce(state, (draft) => {
    switch (action.type) {

      case "user_update":{
        const {userInfo} = action.payload;
        draft.user_id             = userInfo.id
        draft.email               = userInfo.email
        draft.vip                 = userInfo.vip
        draft.points_available    = userInfo.points_available
      }

      default: {
        break;
      }

    }
  });
}
