import React from 'react';
import {
  Text,
  createStyles
} from '@mantine/core';

const useStyles = createStyles((theme, _params, getRef) => {
  return {
    title: {
      textTransform: 'uppercase',
      letterSpacing: 0.5
    }
  }
})

export function LogoImage(props) {
  const classes = useStyles()
  return (
    <Text weight={800} size="xl" className={classes.title} ml="sm">
      GPTDOS
    </Text>
  )
}
