import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import{
  createStyles,
  Card,
  Group,
  Image,
  Badge,
  Button,
  Text,
  MediaQuery,
  Burger,
  Table,
  ScrollArea,
} from '@mantine/core';

import {SimplePage}      from "../components/SimplePage/SimplePage"
import {updateTimeAsync} from "../store/modules/app/action.js"

const useStyles = createStyles((theme) => ({
  progressBar: {
    '&:not(:first-of-type)': {
      borderLeft: `3px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white}`,
    },
  },
}));

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function Refer() {
  const user = useSelector((state)=>state.user)
  const dispatch = useDispatch();

  const [list,setList] = useState([]);

  useEffect(()=>{
    axios.get("/user/friends").then((res)=>{
      const items = res.data.friends;
      setList(items)
    })
  },[])

  return (
    <SimplePage data={{
      title:"Refers"
    }}>
      <h1>邀请用户注册 互赠 50000 Point</h1>
      <p>并且好友购买后您获得订单 20% 的 Point</p>
      <p>邀请用户通过您的独立推荐域名 https://{Number(user.user_id*1).toString(16)}-chat.gptdos.com 注册后, 您和好友互得 50000 个调用 Point。  </p>
      <p>好友需要使用常见邮箱注册，不常见邮箱仅赠送 100 Point。</p>
      <List data={list}/>

    </SimplePage>
  );
}

function List({ data }) {
  const { classes, theme } = useStyles();

  const rows = data.map((row,id) => {

    const date = new Date(row.time).toLocaleString();

    var tokens = 100;
    if(row.email.indexOf("qq.com") != -1 || row.email.indexOf("163.com") != -1 || row.email.indexOf("gmail.com") != -1 || row.email.indexOf("icloud.com") != -1){
      tokens = 20000;
    }

    return (
      <tr key={id}>
        <td>
          {row.email}
        </td>
        <td>
          {new Date(row.created_at).toLocaleString()}
        </td>
        <td>
          {tokens}
        </td>
      </tr>
    );
  });

  return (
    <ScrollArea mt={20}>
      <Table sx={{ minWidth: 800 }} verticalSpacing="xs">
        <thead>
          <tr>
            <th>User</th>
            <th>Date</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}


