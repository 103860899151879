import { createStyles } from '@mantine/core';

const HEADER_HEIGHT = 50;
const NAVBAR_WIDTH  = 120;
const NAVBAR_BREAKPOINT = 120;

export default createStyles((theme, { shouldRenderHeadertrue }) => ({

  main: {
    background: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[0],
    paddingLeft:0,
  },

  header: {
    top: 0,
    left: 0,
    right: 0,
    height: HEADER_HEIGHT,
    zIndex: 6,
    position: 'fixed',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2]
    }`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 'var(--removed-scroll-width, 0px)',
  },

  brand: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: -10,
    marginRight: -10,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  navbar: {
    boxSizing: 'border-box',
    height: '100vh',
    borderRight: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[2]
    }`,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    position: 'fixed',
    zIndex: 5,
    top: 0,
    bottom: 0,
    left: 0,
    width: NAVBAR_WIDTH,

    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      display: 'none',
    },
  },

  body: {
    paddingRight: theme.spacing.md,
    paddingBottom: theme.spacing.xl * 2,
    paddingLeft: theme.spacing.md,
    paddingTop: 0,//HEADER_HEIGHT,
    height:"100vh",

    [`@media (max-width: ${NAVBAR_BREAKPOINT}px)`]: {
      paddingBottom: 120,
    },
  },

  content: {
    minHeight: 'calc(100vh - 280px)',
  },

}));
